import icons from '../../assets/iconFonts/iconfont.json';
import { ref, unref, reactive, toRefs, watch, onMounted, nextTick } from "vue";
export default {
  __name: 'index',
  emits: ['selected'],
  setup(__props, {
    expose,
    emit
  }) {
    expose();
    const state = reactive({
      name: '',
      iconList: icons.glyphs
    });
    const filterIcons = function () {
      if (state.name) {
        state.iconList = state.iconList.filter(item => item.name.includes(state.name));
      } else {
        state.iconList = icons.glyphs;
      }
    };
    const selectedIcon = function (name) {
      emit('selected', name);
      document.body.click();
    };
    const reset = function () {
      state.name = '';
      state.iconList = icons.glyphs;
    };
    const __returned__ = {
      emit,
      state,
      filterIcons,
      selectedIcon,
      reset,
      get icons() {
        return icons;
      },
      ref,
      unref,
      reactive,
      toRefs,
      watch,
      onMounted,
      nextTick
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};