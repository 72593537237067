import { toHandlers as _toHandlers, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-c8a70580"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["xlink:href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return $options.isExternal ? (_openBlock(), _createElementBlock("div", _mergeProps({
    key: 0,
    style: $options.styleExternalIcon,
    class: "svg-external-icon svg-icon"
  }, _toHandlers(_ctx.$listeners, true)), null, 16 /* FULL_PROPS */)) : (_openBlock(), _createElementBlock("svg", _mergeProps({
    key: 1,
    class: $options.svgClass,
    "aria-hidden": "true"
  }, _toHandlers(_ctx.$listeners, true), {
    style: {
      color: $props.color
    }
  }), [_createElementVNode("use", {
    "xlink:href": $options.iconName
  }, null, 8 /* PROPS */, _hoisted_1)], 16 /* FULL_PROPS */));
}