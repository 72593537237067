import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_pagination = _resolveComponent("el-pagination");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{
      hidden: $props.hidden
    }, "pagination-container"])
  }, [_createVNode(_component_el_pagination, _mergeProps({
    background: $props.background,
    "current-page": $setup.currentPage,
    "page-size": $setup.pageSize,
    layout: $props.layout,
    "page-sizes": $props.pageSizes,
    total: $props.total
  }, _ctx.$attrs, {
    class: "pagination",
    onSizeChange: _ctx.handleSizeChange,
    onCurrentChange: _ctx.handleCurrentChange
  }), null, 16 /* FULL_PROPS */, ["background", "current-page", "page-size", "layout", "page-sizes", "total", "onSizeChange", "onCurrentChange"])], 2 /* CLASS */);
}