import { ref, onMounted, watch } from "vue";
import { ElForm, ElMessage } from "element-plus";
import request from '../../utils/request';
export default {
  __name: 'index',
  props: {
    url: {
      type: String,
      default: ''
    },
    modelValue: {
      type: Object,
      default: () => {
        return {};
      }
    },
    placeholder: {
      type: String,
      default: '点击右侧选择'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    // tab页内容
    tabsList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    noTabsListTip: {
      type: String,
      default: '数据错误！'
    },
    tableHeader: {
      type: Array,
      default: () => {
        return [];
      }
    },
    showSearch: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  setup(__props, {
    expose,
    emit
  }) {
    expose();
    const props = __props;
    const dialogFormVisible = ref(false);
    const loading = ref(false);
    const inputStr = ref('');
    const table = ref(null);
    const selectionRows = ref([]);
    const selectionKeys = ref([]);
    const tableData = ref(null);
    const activeName = ref('');
    const formData = ref([]);
    const selectValue = ref({});
    const cloneValue = ref({});
    const formHandle = [{
      label: "搜索",
      type: "primary",
      icon: "el-icon-search",
      handle: () => getList()
    }, {
      label: "重置",
      icon: "el-icon-refresh",
      class: "commonDarkBtn",
      handle: () => resetQuery()
    }];
    const queryParams = ref({
      pageNum: 1,
      pageSize: 5
    });
    const getList = function (flag) {
      if (!flag) {
        activeName.value = props.tabsList?.length ? props.tabsList[0].name : '';
      }
      let currentTab = {};
      for (let item of props.tabsList) {
        if (item.name === activeName.value) {
          currentTab = item;
        }
      }
      if (!currentTab.url) {
        tableData.value = {
          rows: [],
          total: 0
        };
        return;
      }
      loading.value = true;
      request({
        url: currentTab.url,
        method: 'post',
        data: queryParams.value
      }).then(res => {
        tableData.value = res.data;
        const keys = [];
        for (let key in selectValue.value) {
          if (key === activeName.value) {
            for (let select of selectValue.value[key]) {
              keys.push(select.psrId);
            }
          }
        }
        selectionKeys.value = keys;
      }).catch(err => {}).finally(() => {
        loading.value = false;
      });
    };
    const open = function () {
      if (!props.tabsList?.length) {
        ElMessage.closeAll();
        ElMessage.warning(props.noTabsListTip);
        return;
      }
      cloneValue.value = JSON.parse(JSON.stringify(props.modelValue));
      selectValue.value = props.modelValue;
      dialogFormVisible.value = true;
      resetQuery();
    };
    const cancel = function () {
      emit("update:modelValue", cloneValue.value);
      dialogFormVisible.value = false;
    };
    const confirm = function () {
      dialogFormVisible.value = false;
      let labelStr = '';
      for (let key in selectValue.value) {
        for (let item of selectValue.value[key]) {
          if (labelStr) {
            labelStr += `,${item.name}`;
          } else {
            labelStr += item.name;
          }
        }
      }
      inputStr.value = labelStr;
      emit("update:modelValue", selectValue);
    };
    const handleSelect = function (selection, row) {
      if (selectValue.value[activeName.value]?.length) {
        // 判断是为选中还是去勾选
        const isSelect = selection.some(item => item.psrId === row.psrId);
        if (isSelect) {
          const ids = [];
          for (let item of selectValue.value[activeName.value]) {
            ids.push(item.psrId);
          }
          for (let i = 0; i < selection.length; i += 1) {
            if (!ids.includes(selection[i].psrId)) {
              selectValue.value[activeName.value].push(selection[i]);
            }
          }
        } else {
          const index = selectValue.value[activeName.value].findIndex(item => item.psrId === row.psrId);
          selectValue.value[activeName.value].splice(index, 1);
        }
      } else {
        selectValue.value[activeName.value] = selection;
      }
      console.log(selectValue.value);
    };
    // 标识是否是tabschange触发，此触发，不需要给定默认activename
    const resetQuery = function (flag) {
      queryParams.value = {
        pageNum: 1,
        pageSize: 5
      };
      formData.value.forEach(item => {
        queryParams.value[item.prop] = '';
      });
      getList(flag);
    };
    const handleChange = function (val) {
      activeName.value = val;
      resetQuery(true);
    };
    // 外部变更绑定的值，清空内部
    watch(() => props.modelValue, newVal => {
      let labelStr = '';
      for (let key in newVal) {
        for (let item of newVal[key]) {
          if (labelStr) {
            labelStr += `,${item.name}`;
          } else {
            labelStr += item.name;
          }
        }
      }
      inputStr.value = labelStr;
    }, {
      immediate: true
    });
    const __returned__ = {
      props,
      emit,
      dialogFormVisible,
      loading,
      inputStr,
      table,
      selectionRows,
      selectionKeys,
      tableData,
      activeName,
      formData,
      selectValue,
      cloneValue,
      formHandle,
      queryParams,
      getList,
      open,
      cancel,
      confirm,
      handleSelect,
      resetQuery,
      handleChange,
      ref,
      onMounted,
      watch,
      get ElForm() {
        return ElForm;
      },
      get ElMessage() {
        return ElMessage;
      },
      get request() {
        return request;
      }
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};