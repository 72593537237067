import { ref, unref, reactive, toRefs, watch, onMounted, nextTick } from "vue";
export default {
  __name: 'index',
  props: {
    needSearch: {
      type: Boolean,
      default: false
    },
    infoList: {
      type: Array,
      default: []
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  emits: ["getSearchInfo"],
  setup(__props, {
    expose,
    emit: emits
  }) {
    expose();
    const props = __props;
    const listContainerRef = ref(null);
    const state = reactive({});
    const handleScroll = function () {
      if (props.needSearch) {
        const container = listContainerRef.value;
        if (container?.scrollTop === 0) {
          if (!props.loading) {
            emits('getSearchInfo');
          }
        }
      }
    };
    /**
     * 点击放大缩小图片
     * @param obj
     */
    const fnClickImg = function (imgPath) {
      const image = new Image();
      image.src = imgPath;
      image.onload = () => {
        //创建弹出层
        const previewContatiner = document.createElement('div');
        previewContatiner.style.position = 'fixed';
        previewContatiner.style.top = 0;
        previewContatiner.style.bottom = 0;
        previewContatiner.style.left = 0;
        previewContatiner.style.right = 0;
        previewContatiner.style.zIndex = 9999;
        previewContatiner.style.backgroundColor = 'rgba(0,0,0,0.8)';
        previewContatiner.style.display = 'flex';
        previewContatiner.style.justifyContent = 'center';
        previewContatiner.style.alignItems = 'center';
        document.body.appendChild(previewContatiner);
        //在弹出层增加图片
        const previewImage = document.createElement('img');
        previewImage.src = imgPath;
        previewImage.style.maxWidth = '80%';
        previewImage.style.maxHeight = '80%';
        previewImage.style.zIndex = 9999;
        previewContatiner.appendChild(previewImage);
        //点击弹出层，关闭预览
        previewContatiner.addEventListener('click', () => {
          document.body.removeChild(previewContatiner);
        });
      };
      image.onerror = function () {
        console.log('图片加载失败');
      };
    };
    watch(() => props.infoList, (newVal, oldVal) => {
      if (!oldVal || oldVal.length === 0) {
        nextTick(() => {
          const container = listContainerRef.value;
          container.scrollTop = container.scrollHeight;
        });
      } else {
        const container = listContainerRef.value;
        const tempHeight = container.scrollHeight;
        nextTick(() => {
          container.scrollTop = container.scrollHeight - tempHeight;
        });
      }
    }, {
      immediate: true
    });
    const __returned__ = {
      get props() {
        return props;
      },
      set props(v) {
        props = v;
      },
      emits,
      listContainerRef,
      state,
      handleScroll,
      fnClickImg,
      ref,
      unref,
      reactive,
      toRefs,
      watch,
      onMounted,
      nextTick
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};