import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_drawer = _resolveComponent("el-drawer");
  return _openBlock(), _createBlock(_component_el_drawer, {
    modal: $props.showModal,
    title: $props.title,
    "custom-class": $props.isDetail ? 'disabledDialog' : '',
    "close-on-click-modal": $props.closeOnClickModal,
    modelValue: $props.modelValue,
    direction: "rtl",
    size: "450px",
    "before-close": $setup.visibleHandle
  }, {
    footer: _withCtx(() => [_renderSlot(_ctx.$slots, "footer")]),
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["modal", "title", "custom-class", "close-on-click-modal", "modelValue", "before-close"]);
}